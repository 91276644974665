<template>
  <section v-if="university_details" class="university-header">
    <img
      v-if="university_details.university_image"
      :src="FILESURL + university_details.university_image"
      width="100%"
    />
    <img v-else src="@/assets/images/banner/uni-img.png" width="100%" />
    <div class="container-fluid mt-n5">
      <div class="col-lg-12 px-0">
        <div class="box-shadow py-2 px-2">
          <div class="row">
            <div class="col-md-3 col-xl-2">
              <img :src="FILESURL+university_details.university_logo" width="100%" />
            </div>
            <div class="col-md-9 col-xl-7">
              <div class="d-flex align-items-center flex-wrap">
                <div class="uni-name mr-4">
                  {{ university_details.university_name }}
                </div>
                <div v-if="!isNaN(parseInt(university_details.rating_stars))" class="d-flex mr-4">
                  <img
                    src="@/assets/images/svg/star-yellow.svg"
                    alt=""
                    class="mr-2"
                    v-for="(data, index) in parseInt(
                      university_details.rating_stars
                    )"
                    :key="index + 'rating_star'"
                  />
                </div>
                <!-- <div class="uni-rank">
                  Rank - <span class="font-weight-bold">201</span>
                </div> -->
              </div>
              <div class="d-flex align-items-center pt-md-1 pt-3 flex-wrap">
                <div class="uni-loc mr-4">
                  <feather-icon size="15 " icon="MapPinIcon" />

                  {{ university_details.country_name }}
                  {{
                    university_details.state_name
                      ? "," + university_details.state_name
                      : ""
                  }}
                </div>
                <div class="uni-loc">
                  <feather-icon size="15 " icon="MapPinIcon" />
                  {{ university_details.city_name }}
                </div>
              </div>
              <div></div>
            </div>
          </div>
          <!-- <div class="row mx-0 pt-2">
            <div class="col-lg-12 border-top">
              <ul class="header-list p-0 m-0 pt-1">
                <li>
                  <a href="#benefits">Benefits</a>
                </li>
                <li>
                  <a href="#ranking">Ranking</a>
                </li>
                <li>
                  <a href="#courses">Courses</a>
                </li>
              </ul>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { FILESURL } from "@/config";

export default {
  name: "Navigation",
  props: {
    university_details: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      FILESURL,
    };
  },
};
</script>

<style>
</style>
